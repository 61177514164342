import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import jsPDF from 'jspdf'

import ProductDetails from '../Details/ProductDetails'
import { emailForm } from '../../../actions'
import './style.css'
import imgData from './SidlerIcon.base64'

class Checklist extends Component {
    renderProducts(products) {
        let res = _.map(products, (p, i) => {
            let type = p.type;
            return (<ProductDetails key={i} type={type} status="checklist" product={p} />)
        })
        return res;
    }

    getPdfBlobURL = () => {
        let data = this.props.checklistData;
        const totalPrice = +data.price;
        var doc = new jsPDF();
        doc.setFontSize(13);
        doc.addFont('Arial', 'sans-serif');
        doc.setFont('Arial');
        doc.addImage(imgData, 'JPEG', 25, 18, 20, 20);
        doc.text('SIDLER® International Ltd.', 50, 22);
        doc.text('( 888 )-415-2422', 50, 27);
        doc.text('7626 Winston Street', 50, 32);
        doc.text('Burnaby, BC V5A 2H4, CANADA', 50, 37);
        doc.addFont('Arial', 'sans-serif');
        doc.setFont('Arial');
        doc.setFontSize(19);
        doc.setFontStyle('bold')
        doc.text('Configuration number: ' + data.order_id.toString(), 25, 58);
        doc.addFont('Arial', 'sans-serif');
        doc.setFont('Arial');
        doc.setFontStyle('bold');
        doc.text('Total price: ' + totalPrice.toFixed(2) + ' ' + window.currency.toUpperCase(), 25, 68);
        doc.setFontSize(16);
        doc.addFont('Arial', 'sans-serif');
        doc.setFont('Arial');
        doc.setFontStyle('normal')
        data.products.map((product, i) => {
            const price = +product.price
            let y = 78 + (i * 10);
            doc.text(product.name, 25, y);
            doc.text(price.toFixed(2), 150, y);
            return product;
        });
        doc.setFontSize(13);
        doc.textWithLink('sidler-international.com', 80, 285, { url: 'http://www.sidler-international.com' });
        let blob = doc.output('blob');
        return window.URL.createObjectURL(blob);
    }

    _formPdf = () => {
        // let blocked = document.getElementsByClassName('ad-banner')[0].clientHeight
        let url = this.getPdfBlobURL()
        let link = document.createElement('a')
        link.href = url
        link.download = "checkout.pdf"
        link.click()
    }
    _printPdf = () => {
        let url = this.getPdfBlobURL()
        let w = window.open(url)
        w.print()
    }
    _emailHandleForm = () => {
        window.order_id = this.props.checklistData.order_id
        this
            .props
            .emailForm({ emailFormState: true })
    }
    render() {
        const { checklistData } = this.props;
        let { products, price, order_id } = checklistData;
        price = +price;
        return (
            <div className='checklist'>
                <img className="ad-banner" src="/img/ad.gif" alt='ad' />
                <div className="checklist__back">
                    <button
                        className='checklist__back__buttons checklist__button'
                        onClick={this.props.hideChecklist}>Back</button>
                </div>
                <div className="checklist__wrapper">
                    <div className="checklist__configuration-number">
                        <h3 className="configuration-number__title">Configuration Number</h3>
                        <h3 className="configuration-number__checkcode">#{order_id}</h3>
                    </div>
                    <div className="checklist__details">
                        {this.renderProducts(products)}
                    </div>
                    <div className="checklist__footer">
                        <div className="checklist__additional-icon">
                            <img
                                className="checklist__additional-icon_mail checklist__icon"
                                src="img/icon-mail.svg"
                                alt="mail"
                                onClick={this._emailHandleForm} />
                            <img
                                className="checklist__additional-icon_print checklist__icon"
                                src="img/icon-print.svg"
                                alt="print"
                                onClick={this._printPdf} />
                            <img
                                className="checklist__additional-icon_pdf checklist__icon"
                                src="img/icon-pdf.svg"
                                alt="pdf"
                                onClick={this._formPdf} />
                        </div>
                        <div className="checklist__total-price">
                            <div>Total</div>
                            <div>{price.toFixed(2)} {window.currency.toUpperCase()}</div>
                        </div>
                    </div>
                </div>
                <div className="checklist__locate-dealer">
                    <button
                        onClick={this._formPdf}
                        className='locate-dealer__button checklist__button'>
                        <a href="https://www.sidler-international.com/map/">Locate Dealer</a>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isChecklistFetching: state.navigation.isChecklistFetching,
})

const mapDispatchToProps = dispatch => {
    return {
        emailForm: emailFormState => {
            dispatch(emailForm(emailFormState))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
