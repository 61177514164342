import React, { Component } from 'react';
import { connect } from 'react-redux'
import { removeMirror, removeSideMirror, removeAddon } from '../../../actions';

class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this._formDescription = this._formDescription.bind(this);
        this._formPrice = this._formPrice.bind(this);
        this.renderIcons = this.renderIcons.bind(this);
        this.renderDeleteIcon = this.renderDeleteIcon.bind(this);
        this.renderInfoIcon = this.renderInfoIcon.bind(this);
        this.renderPdfIcon = this.renderPdfIcon.bind(this);
        this._removeObject = this._removeObject.bind(this);
    }


    _formDescription() {
        let res = this.props.product.name;
        return res;
    }

    _formPrice() {
        let price = +this.props.product.price;
        let res = price.toFixed(2) + ` ${window.currency.toUpperCase()}`;
        return res;
    }

    _removeObject() {
        let type = this.props.type;
        switch (type) {
            case 'mirror':
                let id = this.props.mirror._id;
                this.props.removeMirror(id);
                break;
            case 'addon':
                let mirrorId = this.props.mirrorId;
                let addonId = this.props.addon.id;
                this.props.removeAddon({ mirrorId, addonId })
                break;
            case 'side_mirror':
                this.props.removeSideMirror();
                break;
            default:
                break;
        }
    }

    renderDeleteIcon() {
        let res = null;
        let { type, status } = this.props;
        if (type !== 'kit' && status !== 'checklist') {
            res = (<img className='details__icon' src="img/icon-01.svg" alt="" onClick={this._removeObject} />);
        }
        return res;
    }

    renderInfoIcon() {
        let res = null;
        let { type, product } = this.props;
        if (type === 'kit') {
            res = (
                <div className="helper__block">
                    <img className='details__icon icon-info' src="img/icon-03.svg" alt="" />
                    <div className="helper">
                        <h4>{product.description}</h4>
                    </div>
                </div>
            )
        }
        return res;
    }

    renderPdfIcon() {
        let { type, product } = this.props;
        let link = "/product_" + product.id + "_configuration_info.pdf"
        let res = (
            <a href={link} download>
                <img className='details__icon' src="img/icon-02.svg" alt="" />
            </a>
        );
        if (type === 'addon' && this.props.product.type_id === '03') {
            res = null;
        }
        return res;
    }

    renderIcons() {
        let res = (
            <div className="product__list-icon">
                {this.renderDeleteIcon()}
                {this.renderInfoIcon()}
                {this.renderPdfIcon()}
            </div>
        )
        return res;
    }

    render() {
        const children = this.props.children;

        return (
            <div>
                <div className='product__list'>
                    {this.renderIcons()}
                    <div className="product__list-title">
                        <h6 className='product__list-title__h6'>{this._formDescription()}</h6>
                    </div>
                    <div className="product__list-price">
                        <h6 className='product__list-price__h6'>{this._formPrice()}</h6>
                    </div>
                </div>
                {children}
            </div>
        )
    }
}


const mapStateToProps = state => { return {} }
const mapDispatchToProps = dispatch => {
    return {
        removeMirror: (id) => {
            dispatch(removeMirror(id))
        },
        removeAddon: (params) => {
            dispatch(removeAddon(params))
        },
        removeSideMirror: (stage) => {
            dispatch(removeSideMirror())
        }
    }
}

const ProductContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails)
export default ProductContainer;
