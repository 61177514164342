import React, { Component } from 'react';
import { MoonLoader } from 'react-spinners';

import Windows from './Windows/Windows';
import Addons from './Addons/Addons';
import Select from './Select/SelectContainer';
import Details from './Details/DetailsContainer';
import Proceed from './Proceed/Proceed';
import Phonetabs from './Phonetabs/Phonetabs';
import Checklist from './Checklist/Checklist';
import _ from 'lodash';

import './style.css'


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cabinets: true,
            addons: false,
            details: false,
            checklist: false
        }
        this.handleStateChange = this.handleStateChange.bind(this);
        this.showChecklist = this.showChecklist.bind(this);
        this.hideChecklist = this.hideChecklist.bind(this);
        this.findkey = this.findkey.bind(this);
    }

    showChecklist(data) {
        this.props.setConfiguratorStage('checkout');
        this.setState({ checklist: true, checklistData: data })
    }

    hideChecklist() {
        this.props.setConfiguratorStage('construct');
        this.setState({ checklist: false })
    }

    handleStateChange(param) {
        let nextState = {
            cabinets: false,
            addons: false,
            details: false
        };
        nextState[param] = !this.state[param];
        this.setState(nextState);

    }
    findkey() {
        let res = '';
        for (let key in this.state) {
            if (this.state[key] === true) {
                res = key;
                break
            }
        }
        return res;
    }

    _formOrderProductsList() {
        let presentedMirrors = this.props.mirrors;
        let mirrors = _.map(presentedMirrors, this.props.mirrorToProduct);
        let addons = _.chain(presentedMirrors).map(m => m.addons).flatten().filter(a => a.id !== '03').map(this.props.addonToProduct).value();
        let sideMirrorProduct = this.props.sideMirrorProduct;
        let kitCount = presentedMirrors.length ? presentedMirrors.length - 1 : 0;
        let kit = _.map(Array(kitCount), () => this.props.kitProduct);
        let res = _.concat(mirrors, addons, sideMirrorProduct, kit);
        res = _.compact(res);

        return res;
    }

    render() {
        const { isChecklistFetching } = this.props;

        let selectedButton = this.findkey();
        let productsForOrder = this._formOrderProductsList();
        if (isChecklistFetching) {
            return (
                <div className="navigation__container">
                    <div className="loaderContainer">
                        <MoonLoader loading={isChecklistFetching} />
                    </div>
                </div>
            )
        }
        return (

            <div className="navigation__container">
                {this.state.checklist
                    ? <Checklist hideChecklist={this.hideChecklist} checklistData={this.state.checklistData} />
                    : <div className="navigation">
                        <Phonetabs handler={this.handleStateChange} buttonToggle={selectedButton} />
                        <div className='navigation__wrapper'>
                            <div className="cabinets">
                                <Select cabinets={this.state.cabinets} />
                                <Windows cabinets={this.state.cabinets} />
                            </div>
                            <Addons addons={this.state.addons} availableProducts={this.props.availableProducts} />
                        </div>
                        <Details products={productsForOrder} mirrors={this.props.mirrors} details={this.state.details} handler={this.handleStateChange} availableProducts={this.props.availableProducts} />
                        <Proceed products={productsForOrder} showChecklist={this.showChecklist} />
                    </div>
                }

            </div>
        );
    }
}

export default Navigation;
