import React, { Component } from 'react';
import './App.css';
import { apiBase } from './Constants';
import Main from './containers/Main';
import Loaders from './components/loaders/loaders';

class App extends Component {

    constructor(){
        super()
        this.state = {
            currency: false
        }
    }

    setCurrency(currency){
        this.setState({ currency })
        window.currency = currency;
        this.loadProducts();
    }

    loadProducts(){
        let fetch_Url = `${ apiBase }/products`;
        // DEMO URL
        //let fetch_Url = 'http://sidler-test-feature.s3-website.us-east-2.amazonaws.com/products.json';
        fetch(fetch_Url).then(response => response.json())
        .then(data => {
                let products = data.map(p => {
                    p.price = p[`price_${this.state.currency}`];
                    p.type = p.type_name;
                    p.available = !!p.available;
            return p;
        })
            this.props.setProducts(products);
        })
        .catch(e => {
                console.log(e);
            console.log('ERROR');
            return { error: true }
        })

    }

    renderCurrencyModal(){
        return(
            <div className="currency-overlay">
                <div className="currency-select">
                    <p> Select your country</p>
                    <div className="currency-buttons-row">
                        <div className="currency-button" onClick={ () => { this.setCurrency('cad') } }> Canada </div>
                        <div className="currency-button" onClick={ () => { this.setCurrency('usd') } }> USA </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="App">
                <div className="rotate">
                    <img className="rotate__img" src="./img/rotate.png" alt="rotate your phone"/>
                </div>
                { !this.state.currency && this.renderCurrencyModal() }
                { (!this.state.currency && this.props.loading)
                    ? <Loaders /> :
                    <Main />}
            </div>
        );
    }
}

export default App;
