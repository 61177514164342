export const setProducts = (products) => {
    return {
        type: 'SET_AVAILABLE_PRODUCTS',
        products
    }
}

export const setConfiguratorStage = (stage) => {
    return {
        type: 'SET_APP_STAGE',
        stage
    }
}

export const setSelectSize = (size) => {
    return {
        type: 'SET_SELECT_SIZE',
        size
    }
}

export const setMountType = (mountType) => {
    return {
        type: 'SET_MOUNT_TYPE',
        mountType
    }
}

export const addCabinet = (params) => {
    return {
        type: 'ADD_CABINET',
        params
    }
}

export const removeMirror = (index) => {
    return {
        type: 'REMOVE_CABINET',
        index
    }
}
export const addAddon = (params) => {
    return {
        type: 'ADD_ADDON',
        params
    }
}
export const addSideMirror = () => {
    return {
        type: 'ADD_SIDE_MIRROR'
    }
}

export const removeAddon = (params) => {
    return {
        type: 'REMOVE_ADDON',
        params
    }
}
export const removeSideMirror = (stage) => {
    return {
        type: 'REMOVE_SIDE_MIRROR'
    }
}

/*
==============================
        D'n'D actions
==============================
*/

export const startDrag = () => {
    return {
        type: 'START_DRAG'
    }
}
export const endDrag = () => {
    return {
        type: 'END_DRAG'
    }
}
export const requstPreviewSideMirror = () => {
    return {
        type: 'REQUEST_PREVIEW_SIDE_MIRROR'
    }
}
export const requestPreviewVerticalLighting = () => {
    return {
        type: 'REQUEST_PREVIEW_VERTICAL_LIGHTING'
    }
}
export const requestPreviewMirror = (size) => {
    return {
        type: 'REQUEST_PREVIEW_MIRROR',
        size
    }
}

export const requestPreviewAddon = (params) => {
    return {
        type: 'REQUEST_PREVIEW_ADDON',
        params
    }
}

export const emailForm = (emailFormState) => {
    return { 
        type: 'EMAIL_FORM',
        ...emailFormState 
    }
}

export const setActiveMirror = (id) => {
    return { 
        type: 'SET_ACTIVE_MIRROR',
        id
    }
}

export const cancelVerticalLighting = (id) => {
    return { 
        type: 'CANCEL_VERTICAL_LIGHTING',
        id
    }
}

export const addVerticalLighting = (id, direction) => {
    return { 
        type: 'ADD_VERTICAL_LIGHTING',
        id, direction
    }
}