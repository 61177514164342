export const apiBase = 'https://cc.sidler-international.com/api/v2'
// export const apiBase = 'http://0.0.0.0:3000/api/v2'

export const ItemTypes = {
    DEFAULT_MIRROR : 'default_mirror',
    SIDE_MIRROR : 'side_mirror',
    VERTICAL_LIGHTING: 'vertical_lighting',
    ADDON : 'addon'
};
export const MountTypes = [
    {
        id : '01',
        className : 'surface-mount',
        description : 'Surface Mount'
    },
    {
        id : '02',
        className : 'semi-recessed',
        description : 'Semi-Recessed'
    },
    {
        id : '03',
        className : 'recessed',
        description : 'Recessed'
    }
];
