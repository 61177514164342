import React, { Component } from 'react';
import './styles.css';

class Phonetabs extends Component {
    _getClassName(type){
        let defaultClass = 'tabs__button button-'+ type;
        let res = defaultClass;
        if (type === this.props.buttonToggle ){
            res += ' active';

        }
        return res;
    }
    render() {

        return (
            <div className="tabs">

                <button  className={ this._getClassName('cabinets') }
                onClick={ () => this.props.handler('cabinets') }>
                    Cabinets
                </button>

                <button className={ this._getClassName('addons') } onClick={ () => this.props.handler('addons') }>
                    Add-ons
                </button>
                <button className={ this._getClassName('details') } onClick={ () => this.props.handler('details') }>
                    Details
                </button>
            </div>
        );
    }
}

export default Phonetabs;
