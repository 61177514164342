import React, { Component } from 'react';
import ProductDetails from './ProductDetails';
import './styles.css';
import _ from 'lodash';

class Details extends Component {
    constructor(props) {
        super(props);
        this._renderTopWrapper = this._renderTopWrapper.bind(this);
        this._renderProductsInfo = this._renderProductsInfo.bind(this);
        this._renderSideMirror = this._renderSideMirror.bind(this);
        this._renderKit = this._renderKit.bind(this);
        this._renderMirrrors = this._renderMirrrors.bind(this);
        this._renderAddonds = this._renderAddonds.bind(this);
    }

    _renderTopWrapper(imgSrc) {
        return (
            <div className="details__button">
                <div className='navigation__wrapper' onClick={() => this.props.handler('details')}>
                    <img src={imgSrc} alt="" height="15" />
                    <h3 className="details__button__title" >
                        Details
                    </h3>
                </div>
            </div>
        )
    }

    _renderAddonds(addons, mirrorId) {
        let addonsList = _.map(addons, (a, i) => {
            let productAddon = this.props.addonToProduct(a);
            return (
                <ProductDetails key={i} type="addon" product={productAddon} addon={a} mirrorId={mirrorId} />
            )
        })
        let res = addonsList;
        return addons.length ? res : null;
    }

    _renderMirrrors() {
        let renderedMirrors = _.map(this.props.mirrors, (m, i) => {
            let product = this.props.mirrorToProduct(m);
            return (
                <ProductDetails type="mirror" mirror={m} product={product} key={i} >
                    {this._renderAddonds(m.addons, m._id)}
                </ProductDetails>
            )
        })
        return renderedMirrors;
    }

    _renderSideMirror() {
        let sideMirrorProduct = this.props.sideMirrorProduct;
        let sideMirrorComponent = null;
        if (sideMirrorProduct) {
            sideMirrorComponent = (
                <ProductDetails type="side_mirror" product={sideMirrorProduct} />
            );
        }
        return sideMirrorComponent;
    }

    _renderKit() {
        let kitProduct = this.props.kitProduct;
        let kitComponents = null;
        if (kitProduct) {
            let kitCount = this.props.mirrors.length - 1;
            kitComponents = _.map(Array(kitCount), (k, i) => <ProductDetails key={i} type="kit" product={kitProduct} />
            );
        }
        return kitComponents;
    }

    _renderProductsInfo() {
        return (
            <div className="details__wrapper__button">
                <div className='details__info'>
                    <div className=" details__info__window">
                        {this._renderMirrrors()}
                        {this._renderSideMirror()}
                        {this._renderKit()}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const showDetails = this.props.details;
        const DETAILS_IMG = showDetails ? "img/arrow-down.svg" : "img/arrow-up.svg";
        return (

            <div className='details'>
                {this._renderTopWrapper(DETAILS_IMG)}
                {showDetails &&
                    this._renderProductsInfo()
                }
            </div>

        );
    }
}
export default Details;
