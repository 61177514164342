import { connect } from 'react-redux'
import { addCabinet, startDrag, endDrag, requestPreviewMirror } from '../../../actions'
import Mirror from './Mirror'
import _ from 'lodash'

const mapStateToProps = state => {
    const isProductAvailable = (params) => {
        let lProduct = _.find(state.app.availableProducts, params);
        return lProduct && lProduct.available !== "0";
    }

    return {
        selectSize: state.app.selectParams.size,
        isProductAvailable: isProductAvailable
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCabinet: (params) => {
            dispatch(addCabinet(params))
        },
        requestPreviewMirror: (size) => {
            dispatch(requestPreviewMirror(size))
        },
        startDrag: () => {
            dispatch(startDrag())
        },
        endDrag: () => {
            dispatch(endDrag())
        }
    }
}

const MirrorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Mirror)

export default MirrorContainer