import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import ReactSVG from 'react-svg';
import _ from 'lodash';
import './styles.css';
import PreviewMirror from '../PreviewMirror/PreviewMirrorContainer';
import { ItemTypes, MountTypes } from '../../../Constants';

const cellTarget = {
    canDrop(props, monitor) {
        var canDrop = false;
        let mirrorId = props.mirror._id;
        let addonId = monitor.getItem().id;
        canDrop = props.canMoveAddon({ mirrorId, addonId });
        return canDrop;
    },
    //THIS RETURNS OBJECT TO IT'S dragSource endDrag METHOD
    drop(props, monitor) {
        let mirrorId = props.mirror._id;
        return { mirrorId };
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}


class PresentedMirror extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            openSide: 'left',
            touchScreen: false,
        }
        this._imgSrc = this._imgSrc.bind(this);
        this._getSvgClass = this._getSvgClass.bind(this);
        this.handleSideChange = this.handleSideChange.bind(this);
    }

    componentWillMount(){
        this.handleTouchScreen()
    }

    _imgSrc() {
        const mirror = this.props.mirror;
        let leftVl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}` && el.description.indexOf("(left)") >= 0);
        let rightVl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}` && el.description.indexOf("(right)") >= 0);
        let mirrorWidth = mirror.size.width;
        let mirrorHeight = mirror.size.height;
        let res = "img/presentation/" + mirrorWidth + "x" + mirrorHeight + "x4";
        if(leftVl.length > 0){
            res += "-L"
        }
        if(rightVl.length > 0){
            res += "-R"
        }
        return res + ".svg";
    }

    _vlImgSrc() {
        const mirror = this.props.mirror;
        let mirrorDepth = mirror.size.depth;
        let mirrorHeight = mirror.size.height;
        let res = "img/light-" + mirrorDepth + "x" + mirrorHeight + ".svg";
        return res;
    }

    handleTouchScreen = () => {
        if ("ontouchstart" in document.documentElement) {
            this.setState({
                touchScreen: !this.state.touchScreen,
            })
        }
    }

    _getSvgClass() {
        let handleAddon = ''
        let resHandleAddon = ''
        if (this.state.isOpen && this.state.openSide === 'left') { handleAddon = '-L-O' }
        if (!this.state.isOpen && this.state.openSide === 'left') { handleAddon = '-L' }
        if (this.state.isOpen && this.state.openSide === 'right') { handleAddon = '-R-O' }
        if (!this.state.isOpen && this.state.openSide === 'right') { handleAddon = '-R' }
        const mirror = this.props.mirror;
        let initialStyle = "mirror__presentation width-" + this.props.mirror.size.width;
        let leftVl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}` && el.description.indexOf("(left)") >= 0);
        let rightVl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}` && el.description.indexOf("(right)") >= 0);
        if(leftVl.length > 0){
            initialStyle += "l"
        }
        if(rightVl.length > 0){
            initialStyle += "r"
        }
        let mountTypeId = this.props.mountTypeId;
        let res = initialStyle;
        let addons = _.reduce(mirror.addons, (r, a) => {
            if (a.id === '05') {
                r += ' has-addon-' + a.id + handleAddon;
                resHandleAddon = ' has-addon-' + a.id + handleAddon;
            }
            else {
                r += ' has-addon-' + a.id;
            }
            return r;
        }, "");

        let doorsOpen = " is-open-R";
        if (this.state.openSide === 'left') {
            doorsOpen = " is-open-L";
        }

        if (this.state.isOpen) {
            res += addons;
            res += doorsOpen;
        }
        if (!this.state.isOpen) {
            res += resHandleAddon;
        }
        //recessed type removes sideMirror
        if (this.props.isSideMirror && mountTypeId !== '03') {
            let isSideMirrorClass = " has-addon-01";
            res += isSideMirrorClass;
        }
        let depth = mirror.size.depth;
        let mountType = _.find(MountTypes, { id: mountTypeId });
        let mountTypeClass = ' ' + mountType.className + '-' + depth;
        res += mountTypeClass;
        return res;
    }

    handleSideChange() {
        let nextSide = this.state.openSide === 'right' ? 'left' : 'right';
        this.setState({ openSide: nextSide });
    }

    renderSideChoose() {
        const mirror = this.props.mirror;
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '15px',
                    left: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 10000
                }}
            >
                <p className="drop-vl__desc">Choose a side to mount vertical lighting</p>
                <div className="pulsating-circle pulsating-circle_left" onClick={() => {this.props.addVerticalLighting(mirror._id, "left")}}></div>
                <div className="pulsating-circle pulsating-circle_right" onClick={() => {this.props.addVerticalLighting(mirror._id, "right")}}></div>
                <div className="drop-vl__cancel" onClick={() => {this.props.cancelVerticalLighting(mirror._id)}}>Cancel</div>
            </div>
        );
    };

    renderOverlay(koef) {
        const mirror = this.props.mirror;
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    opacity: koef
                }}
            >
                <PreviewMirror mirror={mirror} />
            </div>
        );
    };

    handleRemoveMirror(mirror) {
        this.props.removeMirror(mirror._id);
    }

    renderButtons() {
        const mirrorButtonClass = ((this.state.touchScreen && (this.props.mirror._id === this.props.activeMirrorId)) ? "mirror__button__touch" : "mirror__button")
        const mirror = this.props.mirror;
        let res = null;
        if (this.props.appStage !== 'checkout') {
            res = (
                <div className="mirror-hover" onClick={() => {this.props.setActiveMirror(this.props.mirror._id)}}>
                    <div className={mirrorButtonClass} style={{ flexDirection: 'row' }}>
                        <button className="open__close" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                            <img src="img/icon-10.png" alt="" srcSet="" className="present__icon" />
                        </button>
                        {this.state.isOpen ?
                            <button className="arrows" onClick={this.handleSideChange}>
                                <img src="img/icon-09.png" alt="" srcSet="" className="present__icon" />
                            </button>
                            : null
                        }
                        <button className="remove" onClick={e => this.handleRemoveMirror(mirror)}>
                            <img src="img/icon-08.png" alt="" srcSet="" className="present__icon" />
                        </button>
                    </div>
                </div>
            )
        }
        return res;
    }

    render() {
        const { connectDropTarget, isOver, canDrop, mirror } = this.props;
        let imgSrc = this._imgSrc();
        let imgClass = this._getSvgClass();
        let addonData = this.props.requstedPreviewAddonData;
        let canDropMobile;
        if (addonData) { 
            let mirrorId = this.props.mirror._id;
            let addonId = addonData.addonId;
            canDropMobile = this.props.canMoveAddon({ mirrorId, addonId });
        }
        let vertical = this.props.mirror.vertical;
        let { opacity, preview } = this.props.sidePreviewParams;
        let vl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}`);
        return connectDropTarget(
            <div className="presented__mirror">
                <ReactSVG src={imgSrc} svgClassName={imgClass} />
                {!vertical && this.renderButtons()}
                {!isOver && canDrop && this.renderOverlay(0.5)}
                {isOver && canDrop && this.renderOverlay(1)}
                {addonData && canDropMobile && this.renderOverlay(1)}
                {preview && this.renderOverlay(opacity)}
                {vertical && this.renderSideChoose()}
            </div>
        )
    }
}


export default DropTarget(ItemTypes.ADDON, cellTarget, collect)(PresentedMirror);
