import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { PRESENT_CELL_BASE_WIDTH_COEF } from '../../../store/coefitients';
import {ItemTypes} from '../../../Constants';
import PreviewMirror from '../PreviewMirror/PreviewMirrorContainer';
import './style.css';

const cellTarget = {
    canDrop(props, monitor) {
        var canDrop = false;
        canDrop = props.canDropMirror;
        return canDrop;
    },
    //THIS RETURNS OBJECT TO IT'S dragSource endDrag METHOD
    drop(props, monitor) {
        let side = props.side;
        return { side };
    }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    dndObject : monitor.getItem()
  };
}

class PreviewCell extends Component {
    static propTypes = {
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired,
        connectDropTarget: PropTypes.func.isRequired
    };
    constructor(props){
        super(props);
        this.renderPreviewComponent = this.renderPreviewComponent.bind(this);
    }


    _calculateWidth (dndObject) {
        let width = PRESENT_CELL_BASE_WIDTH_COEF;
        let size = dndObject.size;
        let side = this.props.side;
        width *= this.props.getPreviewCoef(side, size);
        let containerWidth = this.props.parentWidth;
        if (containerWidth) {
            width = width * 100 / containerWidth;
        }
        let widthStr = width + '%';
        return widthStr;
    }

    renderOverlay(mirror, opacity) {
        let side = this.props.side;
        return (
            <PreviewMirror mirror={mirror} opacity={opacity} side={side}/>
        );
    };

    renderPreviewComponent(isOver, canDrop, canDropOnClick, nextMirror) {
        let res = null;
        
        if (!isOver && canDrop) {
            res = this.renderOverlay(nextMirror, 0.5);
        }
        else if (isOver && canDrop) {
            res = this.renderOverlay(nextMirror, 1);
        }
        else if (canDropOnClick && this.props.preview) {
            res = this.renderOverlay(nextMirror, 1);
        }
        return res;
    }

    render() {
        const { connectDropTarget, isOver, canDrop, dndObject } = this.props;
        let nextMirror = dndObject;
        if (this.props.preview) {
            nextMirror = {size :this.props.requstedPreviewMirrorSize};
        }
        //check for addon. Why - don't know. They are called here smwhy
        if (!(nextMirror && nextMirror.size) || ( dndObject && (dndObject.type === 'addon')) ) {
            nextMirror = {size :{width : 15}};
        }
        let widthStr = this._calculateWidth(nextMirror);
        let canDropOnClick = this.props.canDropMirror;
        let previewComponent = this.renderPreviewComponent(isOver, canDrop, canDropOnClick, nextMirror);
        return connectDropTarget(
            <div key={this.props.key} className="field__cell" style={{ width: widthStr}}>
                {previewComponent}
            </div>
        );
    }
}

export default DropTarget(ItemTypes.DEFAULT_MIRROR, cellTarget, collect)(PreviewCell);
