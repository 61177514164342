import { connect } from 'react-redux'
import { setSelectSize, setMountType } from '../../../actions'
import Select from './Select'

const mapStateToProps = state => {
    return {
        selectSize : state.app.selectParams.size,
        isSideMirror : state.app.isSideMirror,
        mountTypeId : state.app.selectParams.mountType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSelectSize: (size) => {
            dispatch(setSelectSize(size))
        },
        setMountType: (type) => {
            dispatch(setMountType(type))
        }
    }
}

const SelectContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Select)

export default SelectContainer