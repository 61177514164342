import { connect } from 'react-redux'
import { removeMirror, setActiveMirror, cancelVerticalLighting, addVerticalLighting } from '../../../actions'
import PresentedMirror from './PresentedMirror'
import _ from 'lodash';

const mapStateToProps = state => {
    let canMoveAddon = (params) => {
        let { mirrorId, addonId } = params;
        let currMirror = _.find(state.app.presentedMirrors, m => m._id === mirrorId);
        let addons = _.filter(currMirror.addons, a => a.id === addonId)
        let hasAddon = currMirror && addons.length;
        if(addonId == `06h${currMirror.size.height}` && addons.length == 1){ // Special case for Vertical Lighting
            return true;
        }
        return currMirror && !hasAddon;
    }
    return {
        activeMirrorId: state.app.activeMirrorId,
        appStage: state.app.stage,
        availableProducts: state.app.availableProducts,
        mirrors: state.app.presentedMirrors,
        isSideMirror: state.app.isSideMirror,
        canMoveAddon: canMoveAddon,
        mountTypeId: state.app.selectParams.mountType,
        requstedPreviewAddonData: state.app.requstedPreviewAddonData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeMirror: (id) => {
            dispatch(removeMirror(id))
        },
        setActiveMirror: (id) => {
            dispatch(setActiveMirror(id))
        },
        cancelVerticalLighting: (id) => {
            dispatch(cancelVerticalLighting(id))
        },
        addVerticalLighting: (id, direction) => {
            dispatch(addVerticalLighting(id, direction))
        }
    }
}

const PresentedMirrorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PresentedMirror)

export default PresentedMirrorContainer