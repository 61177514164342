import { connect } from 'react-redux'
import { setConfiguratorStage } from '../../actions'
import Navigation from './Navigation'

const mapStateToProps = state => {
    return {
        availableProducts: state.app.availableProducts,
        mirrors: state.app.presentedMirrors,
        kitProduct: state.app.getKitProduct(),
        sideMirrorProduct: state.app.getSideMirrorProduct(),
        mirrorToProduct: state.app.mirrorToProduct,
        addonToProduct: state.app.addonToProduct,
        isChecklistFetching: state.navigation.isChecklistFetching,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setConfiguratorStage: (stage) => {
            dispatch(setConfiguratorStage(stage))
        }
    }
}

const NavigationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation)

export default NavigationContainer
