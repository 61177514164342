import React, { Component } from 'react';
import { PRESENT_CELL_BASE_WIDTH_COEF } from '../../../store/coefitients';
import _ from 'lodash';
import './style.css';

const VL_WIDTH_LABEL = 2.25

class Bottomdetails extends Component {

    formStyleWithPadding() {
        let p = 12 / this.props.mirrors.length;
        p += '%'
        let res = {
            paddingTop : p
        };
        return res;
    }

    renderEachWidth() {
        let mirrors = this.props.mirrors;
        let widthBlocks = [];
        let eachWidth = _.map(mirrors, (m, i) => {
            let width = m.scale * PRESENT_CELL_BASE_WIDTH_COEF;
            const descriptionR = 'Vertical Lighting (right)'
            const descriptionL = 'Vertical Lighting (left)'
            let existingR = _.filter(m.addons, a => a.description === descriptionR)
            let existingL = _.filter(m.addons, a => a.description === descriptionL)
            const vl_width = 1.4 + '%';
            const vl = (i, side) => (
                <div key={`vl-${i}-${side}`} className="mirror_width" style={{width: vl_width}}><div className="mirror_width__line">{VL_WIDTH_LABEL}</div></div>
            )
            let leftVL = existingL.length > 0 ? vl(i, "left") : null
            let rightVL = existingR.length > 0 ? vl(i, "right") : null
            width -= existingL.length * 1.4 + existingR.length * 1.4
            width += '%';
            widthBlocks.push(rightVL)
            widthBlocks.push(
                <div key={i} className="mirror_width" style={{width}}>
                    <div className="mirror_width__line">
                         {m.size.width}
                    </div>
                </div>
            )
            widthBlocks.push(leftVL)
            
            return m
        })
        _.reverse(widthBlocks);
        let res = null;
        if (this.props.mirrors.length && this.props.details) {
            res = (
                <div className="bottomdetails__combain">
                    {widthBlocks}
                </div>
            )
        }
        return res;
    }

    _calculateTotalWidthPercentage() {
        let res = 0;
        let mirrors = this.props.mirrors;
        res = _.reduce(mirrors, (r, m) => {
            r+= m.scale * PRESENT_CELL_BASE_WIDTH_COEF;
            return r;
        }, 0)
        res += '%';
        return res;
    }

    _calculateTotalWidth() {
        let res = 0;
        let mirrors = this.props.mirrors;
        res = _.reduce(mirrors, (r, m) => {
            const descriptionR = 'Vertical Lighting (right)'
            const descriptionL = 'Vertical Lighting (left)'
            let existingR = _.filter(m.addons, a => a.description === descriptionR)
            let existingL = _.filter(m.addons, a => a.description === descriptionL)
            r+= m.size.width + existingR.length * VL_WIDTH_LABEL + existingL.length * VL_WIDTH_LABEL;
            return r;
        }, res)
        return res;
    }

    renderSummary() {
        let width = this._calculateTotalWidthPercentage();
        let res = null;
        if (this.props.mirrors.length && this.props.details) {
            res = (
                <div className='bottomdetails__total' style={{width}}>
                    <div className="bottomdetails__total_line">
                    {this._calculateTotalWidth()}
                    </div>
                </div>
            )
        }
        return res;
    }

    render() {
        let style = this.formStyleWithPadding();
        return (
            <div className='bottomdetails' style = {style}>
                {this.renderEachWidth()}
                {this.renderSummary()}
            </div>
        );
    }
}

export default Bottomdetails;
