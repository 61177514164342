import React, {Component} from 'react';
import MirrorContainer from './MirrorContainer';
import './styles.css';


class Windows extends Component {
    render() {
        var tabsClass = 'model' + (this.props.cabinets ? 'hidden' : '');
        return (
            <div className={tabsClass}>
                <div className='model__header'>
                    <h4 className='model__title'>Model Dimensions </h4>
                </div>
                <div className="windows">
                    <MirrorContainer size={{width : 12}} presentation={false}/>
                    <MirrorContainer size={{width : 15}} presentation={false}/>
                    <MirrorContainer size={{width : 19}} presentation={false}/>
                    <MirrorContainer size={{width : 23}} presentation={false}/>
                </div>
            </div>
        );
    }
}

export default Windows;
