import { connect } from 'react-redux'
import { addCabinet, addAddon, addSideMirror } from '../../../actions'
import { KOEFS, MAX_MIRROR_COUNT } from '../../../store/coefitients';
import PreviewCell from './PreviewCell';
import _ from 'lodash';

const mapStateToProps = state => {
    const calculateScale = (m) => {
        let res = 1;
        let nextMirror = state.app.presentedMirrors[m._id + 1];
        let nextWidth = nextMirror.size.width;
        //choose params based on height
        const HEIGHT_ARR = state.app.heightArr;
        //calculate scale for this current mirror
        let leftVl = _.filter(m.addons, (el) => el.id === `06h${nextMirror.size.height}` && el.description.indexOf("(left)") >= 0);
        let rightVl = _.filter(m.addons, (el) => el.id === `06h${nextMirror.size.height}` && el.description.indexOf("(right)") >= 0);
        let width_key = m.size.width + (leftVl.length ? "l" : "") + (rightVl.length ? "r" : "");
        leftVl = _.filter(nextMirror.addons, (el) => el.id === `06h${nextMirror.size.height}` && el.description.indexOf("(left)") >= 0);
        rightVl = _.filter(nextMirror.addons, (el) => el.id === `06h${nextMirror.size.height}` && el.description.indexOf("(right)") >= 0);
        let width_key_next = nextMirror.size.width + (leftVl.length ? "l" : "") + (rightVl.length ? "r" : "");
        res = (KOEFS[width_key_next] * HEIGHT_ARR[width_key_next]) / (KOEFS[width_key] * HEIGHT_ARR.left);
        //take into account previous mirror's scale
        res *= nextMirror.scale;
        return res;
    }

    const getPreviewCoef = (side, size) => {
        let coef = 1;
        if (state.app.presentedMirrors.length === 0) {

        }
        //lastOne
        else if (side === 'right') {
            coef = calculateScale({ _id: -1, size: size })
        }
        //firstOne
        else if (side === 'left') {
            let curr = state.app.presentedMirrors[state.app.presentedMirrors.length - 1];
            const HEIGHT_ARR = state.app.heightArr;
            let leftVl = _.filter(curr.addons, (el) => el.id === `06h${curr.size.height}` && el.description.indexOf("(left)") >= 0);
            let rightVl = _.filter(curr.addons, (el) => el.id === `06h${curr.size.height}` && el.description.indexOf("(right)") >= 0);
            let width_key = curr.size.width + (leftVl.length ? "l" : "") + (rightVl.length ? "r" : "");
            coef = ((KOEFS[width_key] * HEIGHT_ARR.left) / (KOEFS[size.width] * HEIGHT_ARR[size.width]));
        }
        return coef;
    }


    const canDropMirror = () => {
        let isFull = (state.app.presentedMirrors.length === MAX_MIRROR_COUNT);
        return !isFull;
    }

    return {
        getPreviewCoef: getPreviewCoef,
        requstedPreviewMirrorSize : state.app.requstedPreviewMirrorSize,
        canDropMirror : canDropMirror()
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addMirror: (params) => {
            dispatch(addCabinet(params))
        },
        addAddon: (params) => {
            dispatch(addAddon(params))
        },
        addSideMirror: () => {
            dispatch(addSideMirror())
        }
    }
}

const PreviewCellContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewCell)

export default PreviewCellContainer