import React, { Component } from 'react';
import PresentedMirrorsCell from './PresentedMirrorsCell';
import './style.css';
import Bottomdetails from './Bottomdetails/Bottomdetails';
import Hint from './Hint/Hint';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';


class Field extends Component {
    constructor(props){
        super(props);
        this.state = {
            details: false
        }

        this.toggleButton = this.toggleButton.bind(this);
        this.renderPlaceholder = this.renderPlaceholder.bind(this);
    }
    toggleButton(){
        this.setState({ details : !this.state.details })
    }

    renderPlaceholder() {
        let res = null;
        if (this.props.mirrors.length === 0 && !this.props.previewOrDrag) {
            res = <Hint />;
        }
        return res;
    }

    renderShowSizeButton() {
        let res = null;
        if (this.props.mirrors.length) {
            res = (
                <div className="mirror-size">
                    <img className="mirror-size__img" src="img/Artboard 10.png" alt="" onClick = {this.toggleButton}/>
                </div>
            )
        }
        return res;
    }

    render() {
        let showDetails = this.state.details && !this.props.previewOrDrag;
        return (
            <div className="Field">
                <NotificationContainer />
                {this.renderPlaceholder()}
                <div className="i_am_hardcoded"/>
                <PresentedMirrorsCell mirrors={this.props.mirrors} details={showDetails}  />
                <Bottomdetails mirrors={this.props.mirrors} details={showDetails} />
                {this.renderShowSizeButton()}
            </div>
        );
    }
}
export default Field;
