import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { ItemTypes } from '../../../Constants';
import { addSideMirror, requstPreviewSideMirror } from '../../../actions';
import { notificateProductNotAvailable } from '../../tips/Tips'

const mirrorSource = {
    beginDrag(props) {
        return {
            type: 'addon'
        };
    },

    canDrag(props, monitor) {
        let canMove = props.addon.available !== '0';
        return canMove;
    },

    endDrag(props, monitor) {
        if (monitor.didDrop()) {
            props.addSideMirror();
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
}

class SideMirror extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this._imgSrc = this._imgSrc.bind(this);
        this.requestPreview = this.requestPreview.bind(this);
    }

    getCursorStyle() {
        let res = 'move';
        if (this.props.addon.available === '0') {
            res = 'not-allowed'
        }
        return res;
    }

    _imgSrc() {
        let res = "img/add-on-01.png";
        return res;
    }

    componentDidMount() {
        const img = new Image();
        img.src = this._imgSrc();
        img.onload = () => {
            return this.props.connectDragPreview(img)
        };
    }

    requestPreview() {
        if (this.props.addon.available) {
            this.props.requstPreviewSideMirror();
        }
        else {
            notificateProductNotAvailable();
        }
    }

    render() {
        const { connectDragSource, isDragging } = this.props;
        let imgSrc = this._imgSrc();
        let opacity = (isDragging || !this.props.addon.available) ? 0.5 : 1;
        return connectDragSource(
            <div className={this.props.className} onClick={this.requestPreview}
                style={{
                    cursor: this.getCursorStyle(),
                    // opacity: opacity
                }}
            >
                <img className='addons__img' src={imgSrc} alt="" />
                <p className='addons__sizeInfo'>{this.props.description}</p>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        addSideMirror: () => {
            dispatch(addSideMirror())
        },
        requstPreviewSideMirror: () => {
            dispatch(requstPreviewSideMirror())
        }
    }
}

SideMirror = DragSource(ItemTypes.SIDE_MIRROR, mirrorSource, collect)(SideMirror);
export default connect(mapStateToProps, mapDispatchToProps)(SideMirror);
