import React, { Component } from 'react';
import './style.css'

class Loaders extends Component {
    render() {
        return (
            <div className='loaders'>
                <div className="loaders__img">
                 <img  src="img/logo75.png" alt=""/>
                </div>
            </div>
        );
    }
}

export default Loaders;