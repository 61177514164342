import { connect } from 'react-redux'
import { setProducts } from '../actions'
import App from '../App.js'

const mapStateToProps = state => {
    return {
        availableProducts: state.app.availableProducts,
        loading: state.app.availableProducts.length ? false : true
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProducts: (products) => {
            dispatch(setProducts(products))
        }
    }
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(App)

export default AppContainer