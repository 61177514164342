import React, { Component } from "react";
import { MountTypes } from '../../../Constants';
import { warningNotification, successNotification } from '../../tips/Tips'
import _ from 'lodash';
import './styles.css';

const HeightArr = [4, 6];

class Select extends Component {
    constructor(props) {
        super(props);
        this.handleMountTypeChange = this.handleMountTypeChange.bind(this);
    }

    renderDepthOptons() {
        let mountTypeId = this.props.mountTypeId;
        let arrToRender = HeightArr;
        //SEMI-RECESSED AND DEPTH 4 NOT ALLOWED
        if (mountTypeId === '02') {
            arrToRender = _.filter(HeightArr, h => { return h !== 4 })
        };
        arrToRender = _.map(arrToRender, (h, i) => {
            return (
                <option key={i} value={h}>{h}"</option>
            )
        });
        return arrToRender;
    }

    renderSizeSelect() {
        return (
            <div className="size__select">
                <div className="select__left">
                    <h4 className="select__text">Height</h4>
                    <select className="select__height" onChange={(e) => this.props.setSelectSize({ height: parseInt(e.target.value, 10) })} value={this.props.selectSize.height}>
                        <option value={31}>31"</option>
                        <option value={40}>40"</option>
                    </select>
                </div>
                <div className="select__presentation">
                    <h4 className="select__text">Depth</h4>
                    <select className="select__depth" onChange={(e) => this.props.setSelectSize({ depth: parseInt(e.target.value, 10) })} value={this.props.selectSize.depth}>
                        {this.renderDepthOptons()}
                    </select>
                </div>
            </div>
        )
    }

    renderMountTypeOptons() {
        let depth = this.props.selectSize.depth;
        let arrToRender = MountTypes;
        //SEMI-RECESSED AND DEPTH 4 NOT ALLOWED
        if (depth === 4) {
            arrToRender = _.filter(MountTypes, m => { return m.id !== '02' })
        };
        arrToRender = _.map(arrToRender, (m, i) => {
            return (
                <option key={i} value={m.id}>{m.description}</option>
            )
        });
        return arrToRender;
    }

    handleMountTypeChange(e) {
        //sideMirror && recessed case
        if (this.props.isSideMirror && e.target.value === '03') {
            let text = 'Side Mirror is unavailable for Recessed Mount Type, it was deleted';
            warningNotification({ text })
        }
        else if (this.props.isSideMirror && e.target.value !== '03' && this.props.mountTypeId === '03') {
            let text = 'Side Mirror added';
            successNotification({ text })
        }
        this.props.setMountType(e.target.value)
    }

    renderMountTypeSelect() {
        return (
            <div className="mountType">
                <h4 className="mountType__text">Mount Type</h4>
                <select className="select_mountType" onChange={this.handleMountTypeChange} value={this.props.mountTypeId}>
                    {this.renderMountTypeOptons()}
                </select>
            </div>
        )
    }

    render() {
        var tabsClass = 'selectAll' + (this.props.cabinets ? 'hidden' : '');
        return (
            <div className={tabsClass} >
                {this.renderSizeSelect()}
                {this.renderMountTypeSelect()}
            </div>

        );
    }
}
export default Select;
