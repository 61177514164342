import React, {Component} from 'react'
import Addon from './Addon.jsx'
import SideMirror from './SideMirror.jsx'
import { connect } from 'react-redux'
import _ from 'lodash'
import './styles.css'

class Addons extends Component {
    _getProductById(id) {
        let availableProducts = this.props.availableProducts;
        let res = _.find(availableProducts, {type_id : id, type : 'addon'}) || {};
        return res;
    }

    render() {
        var tabsClass = 'addons' + (this.props.addons ? 'hidden' : '');
        return (
            <div className={tabsClass}>
                <div className='addons__header'>
                    <h4 className='addons__title'>Add-ons</h4>
                </div>
                <div className='addons__all'>
                    <div className='addons__top'>
                        <SideMirror className="addons__side" addon = {this._getProductById(`d${this.props.sizes.depth}h${this.props.sizes.height}`)} description="Side Mirror"/>
                        <Addon className="addons__magni" addonId="02" addon = {this._getProductById('02')} description="Magnifying Mirror"/>
                    </div>
                    <div className='addons__bottom'>
                        <Addon className="addons__electrical" addonId="03" addon = {this._getProductById('03')} description="Electrical Outlet"/>
                        <Addon className="addons__cosmetic" addonId="04" addon = {this._getProductById('04')} description="Cosmetic Box"/>
                    </div>
                    <div className='addons__bottom'>
                        <Addon className="addons__handle" addonId="05" addon = {this._getProductById('05')} description="Handle"/>
                        <Addon className="addons__vertical-lighting" addonId={`06h${this.props.sizes.height}`} addon = {this._getProductById(`06h${this.props.sizes.height}`)} description="Vertical Lighting"/>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        sizes: state.app.selectParams.size
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Addons);
