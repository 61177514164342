import React, { Component } from 'react'
import Field from './presentation/Field'
import Navigation from './navigation/NavigationContainer'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import IE from './IE'
import Mailsend from '../components/Mailsend/Mailsend'

class Main extends Component {
    
    render(){
        const { presentedMirrors, previewOrDrag } = this.props;
        return (
            <div className="wrapper">
                <IE/>
                <Navigation />
                { this.props.emailFormState ? <Mailsend/> : null}
                <div className="presentation">
                    <Field mirrors={presentedMirrors} previewOrDrag={previewOrDrag}/>
                </div>
            </div>
        );
    }
}

export default DragDropContext(HTML5Backend)(Main);
