import { KOEFS, MAX_MIRROR_COUNT } from '../store/coefitients';
import { MountTypes } from '../Constants';
import { handleTipsAdd, handleTipsRemove, notificateProductNotAvailableForThisSize, notificateVlIsAlreadyMounted, notificateVlInBetween } from '../components/tips/Tips';
import _ from 'lodash';

const H_31 = {
    "12": 579.3,
    "12r": 579.3,
    "12l": 579.3,
    "12lr": 579.3,
    "15": 573.6,
    "15r": 573.6,
    "15l": 573.6,
    "15lr": 573.6,
    "19": 567.74,
    "19r": 567.74,
    "19l": 567.74,
    "19lr": 567.74,
    "23": 561.16,
    "23r": 561.16,
    "23l": 561.16,
    "23lr": 561.16,
    left: 610.32
}

const H_40 = {
    "12": 610,
    "12r": 610,
    "12l": 610,
    "12lr": 610,
    "15": 603.74,
    "15r": 603.74,
    "15l": 603.74,
    "15lr": 603.74,
    "19": 597.59,
    "19r": 597.59,
    "19l": 597.59,
    "19lr": 597.59,
    "23": 591.44,
    "23r": 591.44,
    "23l": 591.44,
    "23lr": 591.44,
    left: 640.32
}

const initialState = {
    emailFormState: false,
    stage: 'construct',
    availableProducts: [],
    presentedMirrors: [],
    isSideMirror: false,
    selectParams: {
        size: {
            height: 31,
            depth: 4
        },
        mountType: MountTypes[0].id
    },
    heightArr: H_31,
    activeMirrorId: '',


    addonToProduct: () => { },
    mirrorToProduct: () => { },
    getSideMirrorProduct: () => { },
    getKitProduct: () => { },

    /*
    ================
        D'n'D STATE
    ================
    */
    isMirrorDrag: false,
    requstedPreviewMirrorSize: null,
    requstedPreviewSideMirror: false,
    requstedPreviewAddonData: null
}


const app = (state = initialState, action) => {

    //common used vars
    let newSize, currMirror,
        mirrorId, addonId;
    let newMirrors = _.cloneDeep(state.presentedMirrors);

    const mappers = (newState) => {
        return {
            addonToProduct: addonToProductFromState(newState),
            mirrorToProduct: mirrorToProductFromState(newState),
            getSideMirrorProduct: getSideMirrorProductFromState(newState),
            getKitProduct: getKitProductFromState(newState)
        }
    }

    const formState = (changed) => {
        let newState = { ...state, ...dragAndPreviewOff, ...changed };
        let newMappers = mappers(newState);
        return { ...newState, ...newMappers }
    }

    const dragAndPreviewOff = {
        isMirrorDrag: false,
        requstedPreviewMirrorSize: null,
        requstedPreviewSideMirror: false,
        requstedPreviewAddonData: null
    }

    switch (action.type) {
        //app data
        case 'SET_APP_STAGE':
            return formState({ stage: action.stage })
        case 'SET_AVAILABLE_PRODUCTS':
            return { ...state, availableProducts: action.products }
        //email send     
        case 'EMAIL_FORM':
            return { ...state, emailFormState: action.emailFormState }
        //side mirror
        case 'ADD_SIDE_MIRROR':
            return formState({ isSideMirror: true })
        case 'REMOVE_SIDE_MIRROR':
            return formState({ isSideMirror: false })
        case 'SET_ACTIVE_MIRROR': 
            return  { ...state, activeMirrorId: action.id }
        //selectParams
        case 'SET_MOUNT_TYPE':
            newSize = state.selectParams.size;
            if (action.mountType === '02' && state.selectParams.size.depth === 4) {
                newSize.depth = 4;
            }
            return formState({ selectParams: { mountType: action.mountType, size: newSize } })
        case 'SET_SELECT_SIZE':
            newSize = { ...state.selectParams.size, ...action.size };
            if (state.mountType === '02' && action.size.depth === 4) {
                newSize.depth = 4;
            }
            let heightArr = getHeightArr(newSize.height);
            _.forEach(newMirrors, (m, i) => {
                m.size = { ...m.size, ...newSize };
                _.forEach(m.addons, (a) => {
                    if(a.id.indexOf('06h') === 0){
                        a.id = `06h${m.size.height}`
                    }
                })
            })
            return formState({ selectParams: { ...state.selectParams, size: newSize }, heightArr, presentedMirrors: newMirrors })

        //mirrors
        case 'ADD_CABINET':
            let { size, side } = action.params;
            let newMirrorSize = { ...state.selectParams.size, ...size };
            let newMirror = { size: newMirrorSize, addons: [] };
            pushMirror(newMirror, side, newMirrors);
            recalculateIds(newMirrors);
            recalculateScale(newMirrors);
            triggerAddNotification(newMirrors);
            return formState({ presentedMirrors: newMirrors })
        case 'REMOVE_CABINET':
            let index = action.index;
            newMirrors.splice(index, 1);
            // _.remove(newMirrors, { id: addonId });
            let isSideMirror = newMirrors.length == 0 ? false : state.isSideMirror
            recalculateIds(newMirrors);
            recalculateScale(newMirrors);
            triggerDeleteNotification(newMirrors);
            return formState({ presentedMirrors: newMirrors, isSideMirror })

        //addons
        case 'ADD_ADDON':
            mirrorId = action.params.mirrorId;
            addonId = action.params.addonId;
            let description = action.params.description;
            currMirror = _.find(newMirrors, m => m._id === mirrorId);
            // Workaround to handle logic for vertical lightning
            if(addonId === `06h${currMirror.size.height}`){
                currMirror.vertical = true;
            }
            // Workaround to handle logic for 12 inch mirror
            else if(currMirror.size.width === 12 && (addonId === '03' || addonId === '04')) {
              notificateProductNotAvailableForThisSize()
            } else {
              currMirror.addons.push({ id: addonId, description: description })
            }
            return formState({ presentedMirrors: newMirrors })
        case 'REMOVE_ADDON':
            mirrorId = action.params.mirrorId;
            addonId = action.params.addonId;
            currMirror = _.find(newMirrors, m => m._id === mirrorId);
            _.remove(currMirror.addons, { id: addonId });
            if(addonId === `06h${currMirror.size.height}`){
                recalculateScale(newMirrors);
            }
            return formState({ presentedMirrors: newMirrors })
        case 'CANCEL_VERTICAL_LIGHTING': 
            mirrorId = action.id
            currMirror = _.find(newMirrors, m => m._id === mirrorId);
            currMirror.vertical = false;
            return formState({ presentedMirrors: newMirrors })
        case 'ADD_VERTICAL_LIGHTING': 
            mirrorId = action.id
            currMirror = _.find(newMirrors, m => m._id === mirrorId);
            currMirror.vertical = false;
            // Handle one per side logic
            description = `Vertical Lighting (${action.direction})`
            
            const descriptionR = `Vertical Lighting (right)`
            const descriptionL = `Vertical Lighting (left)`
            
            let existingSides = _.filter(currMirror.addons, a => a.description === description)

            // Do not allow adding VL in between
            const currMirrorIdx = _.findIndex(newMirrors, m => m._id === mirrorId);
            const leftMirror = (currMirrorIdx < newMirrors.length - 1) ? newMirrors[currMirrorIdx + 1] : {}
            const rightMirror = (currMirrorIdx > 0) ? newMirrors[currMirrorIdx - 1] : {}

            let blockAddingInBetween = false

            if(leftMirror.addons && _.filter(leftMirror.addons, a => a.description === descriptionR).length > 0 && description === descriptionL) blockAddingInBetween = true
            if(rightMirror.addons && _.filter(rightMirror.addons, a => a.description === descriptionL).length > 0 && description === descriptionR) blockAddingInBetween = true
            
            if(blockAddingInBetween){
                notificateVlInBetween();
            } else{
                if(existingSides.length == 0){
                    currMirror.addons.push({ id: `06h${currMirror.size.height}`, description });
                    recalculateScale(newMirrors);
                } else {
                    notificateVlIsAlreadyMounted();
                }    
            }
            return formState({ presentedMirrors: newMirrors })
        /*
        ================
            D'n'D 
        ================
        */
        case 'START_DRAG':
            return formState({ isMirrorDrag: true })
        case 'END_DRAG':
            return formState({ isMirrorDrag: false })
        case 'REQUEST_PREVIEW_MIRROR':
            let requstedPreviewMirrorSize = { ...state.selectParams.size, ...action.size };
            //if already was requseted -> off preview || full
            if (state.requstedPreviewMirrorSize || state.presentedMirrors.length === MAX_MIRROR_COUNT) {
                requstedPreviewMirrorSize = null;
            }
            return formState({ requstedPreviewMirrorSize })
        case 'REQUEST_PREVIEW_SIDE_MIRROR':
            let requstedPreviewSideMirror = !state.requstedPreviewSideMirror;
            if (state.isSideMirror) {
                requstedPreviewSideMirror = false;
            }
            return formState({ requstedPreviewSideMirror })
        case 'REQUEST_PREVIEW_ADDON':
            let requstedPreviewAddonData = action.params;
            //if already was requseted -> off preview || full
            if (state.requstedPreviewAddonData) {
                requstedPreviewAddonData = null;
            }
            return formState({ requstedPreviewAddonData })

        default:
            return state
    }
}


/*
===============
STORE CALCULATIONS & HELPERS
===============
*/

const mirrorToProductFromState = (state) => m => {
  console.log(state);
  return _.find(state.availableProducts, {type: 'cabinet', type_id: getMirrorTypeId(m)})
}
const addonToProductFromState = (state) => a => {
    return _.find(state.availableProducts, { type: 'addon', type_id: a.id });
}
const getSideMirrorProductFromState = (state) => () => {
    let res = null;
    //recessed and side mirror not allowed;
    if (state.isSideMirror && state.selectParams.mountType !== '03') {
        //hardcoded for request from michael
        console.log("SIDE MIRROR TYPE")
        console.log(state.selectParams.mountType)
        let depth = state.selectParams.mountType == '02' ? '2' : state.selectParams.size.depth
        res = _.find(state.availableProducts, { type: 'addon', type_id:(`d${depth}h${state.selectParams.size.height}`) });
    }

    return res;
}
const getKitProductFromState = (state) => () => {
    let res = null;
    if (state.presentedMirrors.length > 1) {
        res = _.find(state.availableProducts, { type: 'kit', type_id: getKitTypeId(state.selectParams.size) });
    }
    return res;
}

const getMirrorTypeId = (m) => {
    let res = m.size.width + 'x' + m.size.height + 'x' + m.size.depth;
    //case for eOutlet included
    let ElectricalOutletIndex = _.findIndex(m.addons, { id: '03' });
    // NOTE: DISABLING ELECTRICAL OUTLET FOR MIRROR SIZE 12
    if (ElectricalOutletIndex !== -1 && m.size.width > 12) {
        res += 'xe';
    }
    return res;
}

const getKitTypeId = (size) => {
    let res = 'd' + size.depth;
    return res;
}


//good
const getHeightArr = (height) => {
    let res = height === 31 ? H_31 : H_40;
    return res;
}
//good
const recalculateIds = (presentedMirrors) => {
    _.forEach(presentedMirrors, (m, i) => {
        m._id = i;
    })
}
//good
const pushMirror = (newMirror, side, presentedMirrors) => {
    if (presentedMirrors.length === MAX_MIRROR_COUNT) {
        return;
    }
    else if (side === 'right') {
        presentedMirrors.unshift(newMirror);
    }
    else {
        presentedMirrors.push(newMirror);
    }
}

const triggerDeleteNotification = (presentedMirrors) => {
    if (presentedMirrors.length) {
        handleTipsRemove();
    }
}

const triggerAddNotification = (presentedMirrors) => {
    if (presentedMirrors.length !== 1) {
        handleTipsAdd();
    }
}
//good
const recalculateScale = (presentedMirrors) => {
    _.forEachRight(presentedMirrors, (m, i) => {
        if (i === presentedMirrors.length - 1) {
            m.scale = 1;
        }
        else {
            m.scale = calculateScale(m, presentedMirrors);
        }
    })
}
//good
const calculateScale = (m, presentedMirrors) => {
    let res = 1;
    let nextMirror = presentedMirrors[m._id + 1];
    //choose params based on height
    const HEIGHT_ARR = getHeightArr(m.size.height);
    //calculate scale for this current mirror
    let leftVl = _.filter(nextMirror.addons, (el) => el.id === `06h${m.size.height}` && el.description.indexOf("(left)") >= 0);
    let rightVl = _.filter(nextMirror.addons, (el) => el.id === `06h${m.size.height}` && el.description.indexOf("(right)") >= 0);
    let width_key = nextMirror.size.width + (leftVl.length ? "l" : "") + (rightVl.length ? "r" : "")
    leftVl = _.filter(m.addons, (el) => el.id === `06h${m.size.height}` && el.description.indexOf("(left)") >= 0);
    rightVl = _.filter(m.addons, (el) => el.id === `06h${m.size.height}` && el.description.indexOf("(right)") >= 0);
    let curr_width_key = m.size.width + (leftVl.length ? "l" : "") + (rightVl.length ? "r" : "")
    res = (KOEFS[width_key] * HEIGHT_ARR[width_key]) / (KOEFS[curr_width_key] * HEIGHT_ARR.left);
    //take into account previous mirror's scale
    res *= nextMirror.scale;
    return res;
}

export default app