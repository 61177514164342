import React, { Component } from 'react';

import './style.css';

class Hint extends Component {
    render() {
        return (
            <div className="hint">
                <h6 className='hint__text'>Drag and drop cabinets and add-ons here</h6>
            </div>
        );
    }
}

export default Hint;