import React, { Component } from 'react';
import _ from 'lodash';
import './styles.css'

class PreviewMirror extends Component {
    constructor(props){
        super(props);
        this._imgSrc = this._imgSrc.bind(this);
        this._getPresentationClass = this._getPresentationClass.bind(this);
        this.addComponent = this.addComponent.bind(this);
    }

    _imgSrc() {
        const mirror = this.props.mirror;
        let mirrorWidth = mirror.size.width;
        let mirrorHeight = mirror.size.height;
        let res = "img/preview/" + mirrorWidth + "x" + mirrorHeight + "x4-highlited";
        return res + ".png";
    }

    _getPresentationClass() {
        const mirror = this.props.mirror;
        let initialStyle = "mirror__presentation width-" + mirror.size.width;
        let leftVl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}` && el.description.indexOf("(left)") >= 0);
        let rightVl = _.filter(mirror.addons, (el) => el.id === `06h${mirror.size.height}` && el.description.indexOf("(right)") >= 0);
        if(leftVl.length > 0){
            initialStyle += "l"
        }
        if(rightVl.length > 0){
            initialStyle += "r"
        }
        return initialStyle;
    }

    addComponent(ev) {
        let size = this.props.mirror.size;
        let side = this.props.side;
        if (this.props.isRequestedPreviewMirror) {
            let params = {size, side};
            this.props.addMirror(params);
        }
        else if(this.props.requstedPreviewAddonData){
            let mirrorId = this.props.mirror._id;
            let addonData = this.props.requstedPreviewAddonData;
            let params = {mirrorId, ...addonData};
            this.props.addAddon(params);
        }
        else if(this.props.requstedPreviewSideMirror){
            this.props.addSideMirror();
        }
        else if(this.props.requestedPreviewVerticalLighting){
            this.props.addVerticalLighting();
        }
    }

    render() {
        let imgSrc =  this._imgSrc();
        let imgClass = this._getPresentationClass();
        return (
            <div className="presented__mirror" onClick={this.addComponent}>
                <img src={imgSrc} className={imgClass} alt="" style={{height : 'auto'}}/>
                <div className="mirror-hover" />
            </div>
        );
        }
    }

export default PreviewMirror;
