import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export const notificateProductNotAvailable =() =>{
    NotificationManager.warning('Product is not available');
}

export const notificateVlIsAlreadyMounted = () =>{
    NotificationManager.warning('Vertical Lighting is already mounted on this side');
}

export const notificateProductNotAvailableForThisSize =() =>{
  NotificationManager.warning('Product unavailable for this cabinet size');
}

export const handleTipsAdd =() =>{
    NotificationManager.success('Ganging-Kit has been added');
}

export const handleTipsRemove =() =>{
    NotificationManager.warning('Ganging Kit has been removed');
}

export const successNotification = (params) => {
    NotificationManager.success(params.text);
}

export const warningNotification = (params) => {
    NotificationManager.warning(params.text);
}

export const notificateVlInBetween = () => {
	NotificationManager.warning('Double Vertical Lightings are not a possible option');
}