import { connect } from 'react-redux'
import { addCabinet, addAddon, addSideMirror } from '../../../actions'
import PreviewMirror from './PreviewMirror'

const mapStateToProps = state => {
    return {
        isRequestedPreviewMirror : !!state.app.requstedPreviewMirrorSize,
        requstedPreviewSideMirror : state.app.requstedPreviewSideMirror,
        requstedPreviewAddonData : state.app.requstedPreviewAddonData,
        requestedPreviewVerticalLighting: state.app.requestedPreviewVerticalLighting
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addMirror: (params) => {
            dispatch(addCabinet(params))
        },
        addAddon: (params) => {
            dispatch(addAddon(params))
        },
        addSideMirror: () => {
            dispatch(addSideMirror())
        }
    }
}

const PreviewMirrorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewMirror)

export default PreviewMirrorContainer