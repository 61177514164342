import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { ItemTypes } from '../../../Constants';
import './styles.css';

const mirrorSource = {
    beginDrag(props) {
        let size = { ...props.selectSize, ...props.size };
        props.startDrag();
        return { size: size };
    },
    
    canDrag(props, monitor) {
        let size = { ...props.selectSize, ...props.size };
        let type_id = size.width + "x" + size.height + "x" + size.depth;
        let canMove = props.isProductAvailable({ type: 'cabinet', type_id });
        return canMove;
    },

    endDrag(props, monitor) {
        if (monitor.didDrop()) {
            let dropTargetData = monitor.getDropResult();
            const mirror = { size: props.size };
            let params = { size: mirror.size, side: dropTargetData.side };
            props.addCabinet(params)
        }
        props.endDrag()
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
}

class NavMirror extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this._imgSrc = this._imgSrc.bind(this);
        this.requestPreview = this.requestPreview.bind(this);
    }

    componentDidMount() {
        const img = new Image();
        const mirror = { size: this.props.size };
        img.src = "img/jpg/thubmnail-" + mirror.size.width + "_.jpg";
        img.onload = () => this.props.connectDragPreview(img);
    }

    requestPreview() {
        let size = this.props.size;
        this.props.requestPreviewMirror(size);
    }


    _imgSrc() {
        const mirror = { size: this.props.size };
        let mirrorWidth = mirror.size.width;
        let res = "img/jpg/thubmnail-" + mirrorWidth + "_.jpg";
        return res;
    }

    render() {
        const { connectDragSource, isDragging } = this.props;
        const mirror = { size: this.props.size };
        let mirrorWidth = mirror.size.width;
        let imgSrc = this._imgSrc();
        return connectDragSource(
            <div className="windows__drop"
                onClick={this.requestPreview}
                style={{
                    cursor: 'move',
                    opacity: isDragging ? 0.5 : 1
                }}
            >
                <img className="windows__img" src={imgSrc} alt="" />
                <div className='windows__block-sizeInfo' >
                    <p className='windows__sizeInfo'>{mirrorWidth}"</p>
                </div>
            </div>
        );
    }
}

export default DragSource(ItemTypes.DEFAULT_MIRROR, mirrorSource, collect)(NavMirror);
