export const VIEW_WIDTH = 1480;
export const VIEW_HEIGHT = 1080;

const W_12 = 242.28;
const W_12_R = 281.52;
const W_12_L = 283.6;
const W_12_LR = 322.65;

const W_15 = 290.7;
const W_15_R = 329.9;
const W_15_L = 332.6;
const W_15_LR = 371.64;

const W_19 = 340;
const W_19_R = 379.8;
const W_19_L = 381.74;
const W_19_LR = 421.48;

const W_23 = 388.5;
const W_23_R = 427.83;
const W_23_L = 430.7;
const W_23_LR = 469.4;

// const W_12 = 241.8;
// const W_12_R = 241.8;
// const W_12_L = 241.8;
// const W_12_LR = 241.8;

// const W_15 = 290.75;
// const W_15_R = 290.75;
// const W_15_L = 290.75;
// const W_15_LR = 290.75;

// const W_19 = 339.74;
// const W_19_R = 339.74;
// const W_19_L = 339.74;
// const W_19_LR = 339.74;

// const W_23 = 388.55;
// const W_23_R = 388.55;
// const W_23_L = 388.55;
// const W_23_LR = 388.55;

const KOEF_12 = VIEW_WIDTH / W_12;
const KOEF_12_L = VIEW_WIDTH / W_12_L;
const KOEF_12_R = VIEW_WIDTH / W_12_R;
const KOEF_12_LR = VIEW_WIDTH / W_12_LR;
const KOEF_15 = VIEW_WIDTH / W_15;
const KOEF_15_L = VIEW_WIDTH / W_15_L;
const KOEF_15_R = VIEW_WIDTH / W_15_R;
const KOEF_15_LR = VIEW_WIDTH / W_15_LR;
const KOEF_19 = VIEW_WIDTH / W_19;
const KOEF_19_L = VIEW_WIDTH / W_19_L;
const KOEF_19_R = VIEW_WIDTH / W_19_R;
const KOEF_19_LR = VIEW_WIDTH / W_19_LR;
const KOEF_23 = VIEW_WIDTH / W_23;
const KOEF_23_L = VIEW_WIDTH / W_23_L;
const KOEF_23_R = VIEW_WIDTH / W_23_R;
const KOEF_23_LR = VIEW_WIDTH / W_23_LR;

export const PRESENT_CELL_BASE_WIDTH_COEF = 14.5;

export const KOEFS = {
    "12" : KOEF_12,
    "12l": KOEF_12_L,
    "12r": KOEF_12_R,
    "12lr": KOEF_12_LR,
    "15" : KOEF_15,
    "15l": KOEF_15_L,
    "15r": KOEF_15_R,
    "15lr": KOEF_15_LR,
    "19" : KOEF_19,
    "19l": KOEF_19_L,
    "19r": KOEF_19_R,
    "19lr": KOEF_19_LR,
    "23" : KOEF_23,
    "23l": KOEF_23_L,
    "23r": KOEF_23_R,
    "23lr": KOEF_23_LR,
}

export const MAX_MIRROR_COUNT = 4;
