import React, { Component } from 'react';
import {apiBase} from '../../../Constants';
import { connect } from 'react-redux';
import './styles.css';
import _ from 'lodash';

import { fetchChecklistStart, fetchChecklistLoaded } from '../../../actions/navigation'

class Proceed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCaptcha: false
        }
        this.proceed = this.proceed.bind(this);
        this.onRecaptcha = this.onRecaptcha.bind(this);
    }

    componentDidMount = () => {
        if (window.grecaptcha) {
            const { grecaptcha } = window;
            grecaptcha.render('recaptcha', {
                sitekey  : '6LeHzzsUAAAAAKH11Iy5RjYivnZZDrvInL7J1bfQ',
                lang     : 'en',
                size     : 'invisible',
                badge    : 'topleft',
                callback : this.proceed
            });
        }
    };

    _getProductsIds() {
        let products = this.props.products || [];
        return _.map(products, p => parseInt(p.id, 10));
    }

    proceed(captcha) {
        const { fetchChecklist, fetchChecklistLoaded } = this.props;

        let products = this.props.products || [];
        if (!products.length) {
            return;
        }
        let proceed_Url = `${apiBase}/confirm`;
        let ids = this._getProductsIds();
        let dataObj = {
            products: ids,
            recaptcha: captcha,
            currency: window.currency,
        }
        let params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataObj)
        };
        fetchChecklist();
        fetch(proceed_Url, params)
            .then(response => response.json())
            .then(data => {
                data.order_id = data.id;
                data.products = data.products.map((p) => {
                    p.price = p[`price_${window.currency}`]
                    p.type = p.type_name
                    return p
                })
                fetchChecklistLoaded();
                this.props.showChecklist(data);
            })
            .catch(e => {
                console.log(e);
                console.log('ERROR');
                fetchChecklistLoaded();
                return { error: true }
            })
    }


    onRecaptcha = () => {
        const { grecaptcha } = window;
        grecaptcha.execute();
    };

    render() {
        let products = this.props.products || [];
        let buttonStyle = !products.length ? { cursor: "not-allowed", opacity: 0.5 } : {};
        let price = _.reduce(products, (r, p) => {
            r += parseFloat(p.price);
            return r;
        }, 0)
        return (
            <div className="proceed">
                <div id="recaptcha"></div>
                <div className="proceed__price">
                    <h5 className="proceed__price__text"> {price} {window.currency.toUpperCase()}</h5>
                </div>
                <div className="proceed__buy" onClick={this.onRecaptcha} style={{ ...buttonStyle }}>
                    <h5 className="proceed__button">
                        Proceed
                    </h5>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => ({
    fetchChecklist: () => dispatch(fetchChecklistStart()),
    fetchChecklistLoaded: () => dispatch(fetchChecklistLoaded())
})


export default connect(null, mapDispatchToProps)(Proceed);
