import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { ItemTypes } from '../../../Constants';
import { addAddon, requestPreviewAddon } from '../../../actions';
import { notificateProductNotAvailable } from '../../tips/Tips'

const mirrorSource = {
    beginDrag(props) {
        props.requestPreviewAddon()
        return {
            id: props.addonId,
            type: 'addon'
        };
    },

    canDrag(props, monitor) {
        let canMove = props.addon.available;
        return canMove;
    },

    endDrag(props, monitor) {
        if (monitor.didDrop()) {
            let dropTargetData = monitor.getDropResult();
            let params = { mirrorId: dropTargetData.mirrorId, addonId: props.addonId, description: props.description };
            props.addAddon(params)
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
}

class Addon extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this._imgSrc = this._imgSrc.bind(this);
        this.requestPreview = this.requestPreview.bind(this);
    }

    getCursorStyle() {
        let res = 'move';
        if (!this.props.addon.available) {
            res = 'not-allowed'
        }
        return res;
    }

    _imgSrc() {
        const addonId = this.props.addonId.indexOf("06h") === 0 ? "06" : this.props.addonId;
        let res = "img/add-on-" + addonId + ".png";
        return res;
    }

    componentDidMount() {
        const img = new Image();
        img.src = this._imgSrc();
        img.onload = () => {
            return this.props.connectDragPreview(img)
        };
    }

    requestPreview() {
        if (this.props.addon.available) {
            let params = { addonId: this.props.addonId, description: this.props.description };
            this.props.requestPreviewAddon(params);
        }
        else {
            notificateProductNotAvailable();
        }
    }

    render() {
        const { connectDragSource, isDragging } = this.props;
        let imgSrc = this._imgSrc();
        let opacity = (isDragging || !this.props.addon.available) ? 0.5 : 1;
        return connectDragSource(
            <div className={this.props.className} onClick={this.requestPreview}
                style={{
                    cursor: this.getCursorStyle(),
                    opacity: opacity
                }}
            >
                <img className='addons__img' src={imgSrc} alt="" />
                <p className='addons__sizeInfo'>{this.props.description}</p>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        addAddon: (params) => {
            dispatch(addAddon(params))
        },
        requestPreviewAddon: (params) => {
            dispatch(requestPreviewAddon(params))
        }
    }
}


Addon = DragSource(ItemTypes.ADDON, mirrorSource, collect)(Addon);
export default connect(mapStateToProps, mapDispatchToProps)(Addon);
