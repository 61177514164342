import { connect } from 'react-redux'
import Main from '../components/Main'

const mapStateToProps = state => {
    let previewOrDrag = state.app.isMirrorDrag || !!state.app.requstedPreviewMirrorSize;
    return {
        emailFormState: state.app.emailFormState,
        presentedMirrors : state.app.presentedMirrors,
        previewOrDrag
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const MainContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)

export default MainContainer