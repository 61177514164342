import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../Constants';
import PresentMirrorCell from './PresentMirrorCell';
import PreviewCell from './previewCell/PreviewCellContainer';
import { KOEFS, PRESENT_CELL_BASE_WIDTH_COEF, VIEW_HEIGHT, VIEW_WIDTH, MAX_MIRROR_COUNT } from '../../store/coefitients';
import _ from 'lodash';

const cellTarget = {
    canDrop(props, monitor) {
        return props.canMoveSideMirror;
    },
    //THIS RETURNS OBJECT TO IT'S dragSource endDrag METHOD
    drop(props, monitor) {
        return { dropped: true };
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

class PresentedMirrorsCell extends Component {
    static propTypes = {
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
    };

    renderMirrors(params) {
        let res = null;
        let mirrors = this.props.mirrors;
        res = _.map(mirrors, (m, i) => {
            return (
                <PresentMirrorCell key={m._id} mirror={m} sidePreviewParams={params} />
            )
        })
        return res;
    }

    renderInitialPreview() {
        let res = null;
        let preview = this.props.requstedPreviewMirrorSize;
        if (!this.props.mirrors.length) {
            res = (
                <PreviewCell side="default" preview={preview} />
            )
        }
        return res;
    }

    _calculateTotalWidthPercentage() {
        let res = 0;
        let mirrors = this.props.mirrors;
        res = _.reduce(mirrors, (r, m) => {
            r += m.scale * PRESENT_CELL_BASE_WIDTH_COEF;
            return r;
        }, res)
        return res;
    }

    renderPreviewCell(side) {
        let res = null;
        let floatSide = side === 'left' ? 'flex-end' : 'flex-start';
        let preview = this.props.requstedPreviewMirrorSize;
        let previewContainerWidth = (100 - this._calculateTotalWidthPercentage()) / 2;
        let containerWidthStr = previewContainerWidth + '%';
        let previewCell = (this.props.mirrors.length < MAX_MIRROR_COUNT) ? <PreviewCell side={side} preview={preview} parentWidth={previewContainerWidth} /> : null;
        if (this.props.mirrors.length) {
            res = (
                <div className="preview__container" style={{ width: containerWidthStr, justifyContent: floatSide }}>
                    {previewCell}
                    {side === 'right' ? this.renderHeight() : null}
                </div>
            )
        }
        return res;
    }

    renderHeight() {
        let mHeight = this.props.selectSize.height;
        let lastHeight = this.props.heightCellScale;
        let res = null;
        if (this.props.mirrors.length && this.props.details) {
            res = (
                <div className="height__wrapper">
                    <div className="height-right" style={{ height: lastHeight }}>
                        <p>{mHeight}</p>
                    </div>
                </div>
            )
        }
        return res;
    }

    render() {
        const { connectDropTarget, isOver, canDrop } = this.props;
        let opacity = 1;
        let preview = false;
        if (!isOver && canDrop) {
            opacity = 0.5;
            preview = true;
        }
        else if (isOver && canDrop) {
            opacity = 1;
            preview = true;
        }
        else if (this.props.requstedPreviewSideMirror) {
            opacity = 1;
            preview = true;
        }
        return connectDropTarget(
            <div className="presented__mirrors">
                {this.renderInitialPreview()}
                {this.renderPreviewCell('right')}
                {this.renderMirrors({ opacity, preview })}
                {this.renderPreviewCell('left')}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const canMoveSideMirror = () => {
        //no mirrors - can't add side mirror
        let res = !state.app.isSideMirror && state.app.presentedMirrors.length
        return !!res;
    }

    const canMovePresentedCell = () => {
        //no mirrors - can't add side mirror
        const res = state.app.presentedMirrors.length;
        return !!res;
    }

    const getHeightCellScale = () => {
        let res = 100;
        let mirrors = state.app.presentedMirrors;
        let HEIGHT_ARR = state.app.heightArr;
        if (mirrors.length) {
            let lastMirror = state.app.presentedMirrors[0];
            let leftVl = _.filter(lastMirror.addons, (el) => el.id === `06h${lastMirror.size.height}` && el.description.indexOf("(left)") >= 0);
            let rightVl = _.filter(lastMirror.addons, (el) => el.id === `06h${lastMirror.size.height}` && el.description.indexOf("(right)") >= 0);
            let width_key = lastMirror.size.width + (leftVl.length ? "l" : "") + (rightVl.length ? "r" : "");
            let svgInCellPercentage = (0.78 * PRESENT_CELL_BASE_WIDTH_COEF * document.documentElement.clientWidth * VIEW_HEIGHT / (0.3 * 100 * VIEW_WIDTH * document.documentElement.clientHeight));
            res *= (HEIGHT_ARR[width_key] * lastMirror.scale * KOEFS[width_key] * svgInCellPercentage / VIEW_HEIGHT);
        }
        res += '%'
        return res;
    }

    return {
        canMoveSideMirror: canMoveSideMirror(),
        selectSize: state.app.selectParams.size,
        requstedPreviewMirrorSize: state.app.requstedPreviewMirrorSize,
        requstedPreviewSideMirror: state.app.requstedPreviewSideMirror,
        heightCellScale: getHeightCellScale()
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

PresentedMirrorsCell = DropTarget(ItemTypes.SIDE_MIRROR, cellTarget, collect)(PresentedMirrorsCell);
export default connect(mapStateToProps, mapDispatchToProps)(PresentedMirrorsCell)
