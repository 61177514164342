import React, { Component } from 'react';
import { PRESENT_CELL_BASE_WIDTH_COEF } from '../../store/coefitients';
import PresentedMirror from './PresentedMirror/PresentedMirrorContainer';


class PresentMirrorCell extends Component {
    constructor(props){
        super(props);
        this.renderMirror = this.renderMirror.bind(this);
    }

    _calculateWidth () {
        let mirror = this.props.mirror;
        let width = PRESENT_CELL_BASE_WIDTH_COEF;
        width *= mirror.scale;
        let widthStr = width + '%';
        return widthStr;
    }

    renderMirror() {
        let m = this.props.mirror;
        let res  = <PresentedMirror mirror={m} sidePreviewParams={this.props.sidePreviewParams}/>;
        return res;
    }

    render() {
        let widthStr = this._calculateWidth();
        let pieceToRender = this.renderMirror();
        return (
            <div className="field__cell" style={{ width: widthStr}}>
                {pieceToRender}
            </div>
        );
    }
}

export default PresentMirrorCell;
