import { FETCH_CHECKLIST_START, FETCH_CHECKLIST_LOADED } from "../constants/navigation"


const initialState = {
    isChecklistFetching: false,
};

const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHECKLIST_START:
            return { ...state, isChecklistFetching: true }
        case FETCH_CHECKLIST_LOADED:
            return { ...state, isChecklistFetching: false }
        default:
            return state;
    }
}
export default navigationReducer;