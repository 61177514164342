import React, { Component } from 'react';

class IE extends Component {
    render() {
        return (
            <div className="IE">
                <h6 className="IE__title">Sorry, we are not supporting Internet Explorer and Edge browsers right now. Please, use another browser to proceed.</h6>
             </div>
        );
    }
}

export default IE;