import React, { Component } from 'react';
import './style.css'
import {apiBase} from '../../Constants';
import {emailForm} from "../../actions";
import {connect} from "react-redux";
import {successNotification, warningNotification} from "../tips/Tips";

class Mailsend extends Component {
    state = {
        email: "",
    }

    validateEmail = (email) => {
        
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    sendMail = () => {
        const { emailForm } = this.props;
        let proceed_Url = `${apiBase}/email`;
        let dataObj = {
            email: this.state.email,
            id: window.order_id,
        }
        let params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataObj)
        };
        emailForm({ emailFormState: false })
        fetch(proceed_Url, params)
            .then(response => response.json())
            .then(data => {
                successNotification({ text: data.message })
            })
            .catch(e => {
                warningNotification({ text: e.message })
                console.log(e);
                return { error: true }
            })
    }

    handleEmailChange = ({ target }) => {
        const { value = "" } = target;
        this.setState({ email: value.trim() });
    }

    render() {
        const { email } = this.state;
        const isValidEmail = this.validateEmail(email);

        return (
            <div className='mail-send' onClick={() => { this.props.emailForm({ emailFormState: false }) }}>
                <div className="mail-send__block" onClick={(e) => { e.stopPropagation() }} >
                    <div className="wrapper-mail">
                        <h4 className="mail-send__block-title">Email</h4>
                        <input type="email" className="mail-send__block-input" onChange={this.handleEmailChange} value={email} />
                    </div>
                    <button className={`email-send__block-button ${!isValidEmail ? "email-send__invalid-email" : ""}`} onClick={this.sendMail} disabled={!isValidEmail}>Send</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        emailForm: emailFormState => {
            dispatch(emailForm(emailFormState))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Mailsend);