import { connect } from 'react-redux'
import Details from './Details'

const mapStateToProps = state => {
    return {
        availableProducts: state.app.availableProducts,
        mirrors: state.app.presentedMirrors,
        sizes: state.app.selectParams.size,

        kitProduct: state.app.getKitProduct(),
        sideMirrorProduct: state.app.getSideMirrorProduct(),
        mirrorToProduct: state.app.mirrorToProduct,
        addonToProduct: state.app.addonToProduct,
    }
}

const DetailsContainer = connect(mapStateToProps)(Details)

export default DetailsContainer
